@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  
}

code {
  
}

@font-face {
    font-family: 'Ubuntu';
    src: url("./assets/fonts/Ubuntu-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url("./assets/fonts/Ubuntu-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Pixeloid';
    src: url("./assets/fonts/PixeloidMono.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Saturday Moon';
    src: url("./assets/fonts/SaturdayMoon.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cirque Electrique';
    src: url("./assets/fonts/CirqueElectrique.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Undertale';
    src: url("./assets/fonts/Undertale.ttf") format('truetype');
    font-weight: 1000;
    font-style: normal;
}

.tracking-undertale {
    letter-spacing: -0.2em;
}

:root {
    --palette-blue: 11, 0, 128;
}